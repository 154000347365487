import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Select } from '@robinpowered/ui-kit';
import { useMemo } from 'react';
import { useSelectLocations } from './hooks/useSelectLocations';

const { Option } = Select;

export const SelectLocations = ({
  onSelectLocation,
  selectedLocations,
  organizationLocations,
  loading,
}: {
  onSelectLocation: (value: string[]) => void;
  selectedLocations: string[];
  organizationLocations: {
    id: string;
    name: string;
  }[];
  loading: boolean;
}) => {
  const { handleDeselectAll, handleSelectAll, locationOptions } =
    useSelectLocations(
      onSelectLocation,
      selectedLocations,
      organizationLocations
    );
  const { t } = useTranslation('common');

  const options = locationOptions;

  const value = useMemo(
    () =>
      !loading && selectedLocations.length && locationOptions.length
        ? selectedLocations
        : [],
    [locationOptions.length, selectedLocations, loading]
  );

  return (
    <SelectLocationInputWrapper>
      <Select
        data-testid={`location-selector-${loading ? 'loading' : ''}`}
        showSearch
        allowClear
        maxTagCount={1}
        maxTagTextLength={20}
        // TODO: this mode causes a react mem leak error. Tried setting it via use effect
        // with a cleanup, need to keep trying things.  Internal ant design problem. Same problem
        // when trying it with their default 'multi' select example code in our app
        mode={'multiple'}
        placeholder={t(`selectLocation.placeholder`)}
        onChange={(value) => {
          onSelectLocation(value);
        }}
        value={value}
        style={{ width: '100%', maxHeight: '100px' }}
        optionFilterProp="label"
        loading={loading}
        onSelect={handleSelectAll}
        onClear={handleDeselectAll}
      >
        <Option key={t('selectLocation.select_all')}>
          {t(`selectLocation.select_all`)}
        </Option>
        {options.map((option, i) => {
          return (
            <Option
              data-testid={`location-option-${option.value}`}
              key={`${option.value}${i}`}
              value={option.value}
              label={option.label}
            >
              {option.label}
            </Option>
          );
        })}
      </Select>
    </SelectLocationInputWrapper>
  );
};

const SelectLocationInputWrapper = styled.div`
  width: 100%;
  max-width: 275px;
`;
