import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type UseSelectLocationsAPI = {
  selectedLocations: string[];
  handleSelectAll: (value: string) => void;
  handleDeselectAll: () => void;
  locationOptions: { label: string; value: string }[];
  inputText: string;
};

export const useSelectLocations = (
  onSelectLocation: (value: string[]) => void,
  selectedLocations: string[],
  organizationLocations: {
    id: string;
    name: string;
  }[]
): UseSelectLocationsAPI => {
  const { t } = useTranslation('common');

  const handleDeselectAll = useCallback(() => {
    onSelectLocation([]);
  }, [onSelectLocation]);

  const handleSelectAll = useCallback(
    (value) => {
      const allLocationsSelected =
        selectedLocations.length === organizationLocations.length;

      /* Select all spaces if we click the select all option */
      if (value === t('selectLocation.select_all')) {
        if (allLocationsSelected) {
          handleDeselectAll();
        } else {
          onSelectLocation(organizationLocations.map((option) => option.id));
        }
      }
    },
    [
      handleDeselectAll,
      onSelectLocation,
      organizationLocations,
      selectedLocations,
      t,
    ]
  );

  const locationOptions = organizationLocations.map((location) => ({
    label: location.name,
    value: location.id,
  }));

  const inputText = useMemo(() => {
    if (selectedLocations)
      switch (true) {
        case selectedLocations.length < 1:
          return t(`selectLocation.placeholder`);
        case selectedLocations.length > 1:
          return t(`selectLocation.totalSelected`, {
            total: selectedLocations.length,
          });
        case selectedLocations.length >= organizationLocations.length:
          return t(`selectLocation.allLocationsSelected`);
        default:
          return selectedLocations[0];
      }

    return '';
  }, [selectedLocations, organizationLocations, t]);

  return {
    selectedLocations,
    handleSelectAll,
    handleDeselectAll,
    locationOptions,
    inputText,
  };
};
